const $loader = document.querySelector(".loader");
const $openText = $loader.querySelector("span");

window.addEventListener("DOMContentLoaded", () => {
  $world.classList.add("scroll-lock");
  $loader.classList.add("on-load");
});

window.addEventListener("load", () => {
  setTimeout(() => {
    $world.classList.remove("scroll-lock");
    $loader.classList.remove("on-load");
  }, 2500);
  let result = "Hello world.";

  setTimeout(() => {
    $loader.style.display = "none";
  }, 3000);
});

const $mainCharacter = document.querySelector(".main-character");
const $world = document.body;

let characterPosition = {
  top: 0,
  left: 0,
};

let score = 0;

function updateCharacterPosition() {
  $mainCharacter.style.top = characterPosition.top + "px";
  $mainCharacter.style.left = characterPosition.left + "px";
}

function moveUp() {
  characterPosition.top -= 100;
  updateCharacterPosition();
  checkCollision();
}

function moveDown() {
  characterPosition.top += 100;
  updateCharacterPosition();
  checkCollision();
}

function moveLeft() {
  characterPosition.left -= 100;
  updateCharacterPosition();
  checkCollision();
}

function moveRight() {
  characterPosition.left += 100;
  updateCharacterPosition();
  checkCollision();
}
$world.addEventListener("keypress", (e) => {
  if (e.code === "KeyW") {
    moveUp();
  } else if (e.code === "KeyA") {
    moveLeft();
  } else if (e.code === "KeyS") {
    moveDown();
  } else if (e.code === "KeyD") {
    moveRight();
  }
});

const $orb = document.createElement("div");

function orbSpawner() {
  $orb.classList.add("orb");
  $world.appendChild($orb);
  $orb.style.top = Math.random() * window.innerHeight + "px";
  $orb.style.left = Math.random() * window.innerWidth + "px";

  let randomizer = `hsl(${Math.random() * 360}, 100%, 50%)`;
  let randomSize = Math.random() * 100;

  $orb.style.backgroundColor = randomizer;
  $orb.style.boxShadow = `0 0 10px ${randomizer}`;
  $orb.style.width = $orb.style.height = randomSize + "px";

  $orb.addEventListener("click", () => {
    collectOrb($orb, randomSize);
  });
  setTimeout(() => {
    if (document.body.contains($orb)) {
      $orb.remove();
    }
  }, 5000);
}

function collectOrb($orb, size) {
  $orb.remove();
  if (size < 50) {
    score += 1;
  } else {
    score += 5;
  }
  let $score = document.querySelector(".scoreInput");
  $score.innerHTML = score;
}

function checkCollision() {
  const orbs = document.querySelectorAll(".orb");
  orbs.forEach((orb) => {
    const orbRect = orb.getBoundingClientRect();
    const mainCharRect = $mainCharacter.getBoundingClientRect();

    if (
      mainCharRect.left < orbRect.right &&
      mainCharRect.right > orbRect.left &&
      mainCharRect.top < orbRect.bottom &&
      mainCharRect.bottom > orbRect.top
    ) {
      const size = parseFloat($orb.style.width);
      collectOrb($orb, size);
    }
  });
}

setInterval(orbSpawner, 2500);
